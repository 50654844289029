import React from "react";
import { CommonDialog } from "../../components/CommonDialog";
import {
  IVehicle,
  VEHICLE_DOCUMENTS,
  uploadVehicleDocuments,
} from "../../services/vehicle-service";
import { Typography } from "@mui/material";
import { FileUpload } from "../../components/FileUpload";
import { getFileName } from "../../utils";
import { isObject } from "formik";
import toast from "react-hot-toast";
import { t } from "i18next";
interface IVehicleDetailsProps {
  open: boolean;
  data?: IVehicle;
  handleToggleOpen: () => void;
}
const { useState, useEffect } = React;
export const AddDocuments: React.FC<IVehicleDetailsProps> = ({
  handleToggleOpen,
  open,
  data,
}) => {
  const [invoice, setInvoice] = useState<any>(null);
  const [poa, setPoa] = useState<any>(null);
  const dialogClose = () => {
    handleToggleOpen();
  };
  const handleFormSubmit = async () => {
    if (!data?.id) {
      return;
    }
    if (!isObject(invoice) && !isObject(poa)) {
      return;
    }
    const formData = new FormData(); //formdata object
    if (isObject(invoice)) {
      formData.append(VEHICLE_DOCUMENTS.INVOICE, invoice[0]);
    }
    if (isObject(poa)) {
      formData.append(VEHICLE_DOCUMENTS.POA, poa[0]);
    }

    toast.promise(uploadVehicleDocuments(data?.id, formData), {
      loading: "Loading...",
      success: (res) => {
        dialogClose();
        return `Successfully uploaded`;
      },
      error: ({ response }) => {
        return "Error when uploading documents";
      },
    });
  };

  useEffect(() => {
    if (data?.invoice) {
      setInvoice(data?.invoice);
    }
  }, [data?.invoice]);

  useEffect(() => {
    if (data?.powerOfAttorney) {
      setPoa(data?.powerOfAttorney);
    }
  }, [data?.powerOfAttorney]);
  return (
    <CommonDialog
      title={t("Add your documents")}
      open={open}
      handleSubmit={handleFormSubmit}
      handleToggleOpen={dialogClose}
    >
      <Typography>{t("Bill of sale")}</Typography>
      <FileUpload
        onFileSelect={(file: any) => setInvoice(file)}
        onDelete={() => setInvoice(null)}
        value={invoice}
        label={getFileName(invoice) ?? t("Bill of sale")}
      />
      <Typography>{t("Power of Attorney")}</Typography>
      <FileUpload
        onFileSelect={(file: any) => setPoa(file)}
        onDelete={() => setPoa(null)}
        value={poa}
        label={getFileName(poa) ?? t("Power of Attorney")}
      />
    </CommonDialog>
  );
};
