import * as React from "react";
import styled from "styled-components";
import { Document, Page, pdfjs } from "react-pdf";
import DeleteIcon from "@mui/icons-material/Delete";
import { DropzoneDialog } from "mui-file-dropzone";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { downloadDocuments, isValidUrl, mobileVersionWidth } from "../../utils";
import { IconButton } from "@mui/material";
import { CommonDialog } from "../CommonDialog";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
const FileUploadContainer = styled.div`
  display: flex;
  gap: 15px;
  border: 1px solid @media screen and (max-width: ${mobileVersionWidth}px) {
    gap: 2px;
  }
`;
interface Props {
  label: string;
  value?: any;
  dialogTitle?: string;
  onFileSelect: (files: any) => void;
  onDelete: () => void;
  previewMode?: boolean;
}
export const FileUpload: React.FC<Props> = ({
  value,
  label,
  dialogTitle,
  previewMode,
  onFileSelect,
  onDelete,
}) => {
  const [numPages, setNumPages] = React.useState<number>();
  const [open, setOpen] = React.useState(false);
  const [preview, setPreview] = React.useState(false);
  const [files, setFiles] = React.useState<any>([]);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (files: any) => {
    setFiles(files);
    setOpen(false);
    onFileSelect(files);
  };

  const handleOpen = () => {
    if (previewMode) {
      return;
    }
    setOpen(true);
  };

  const previewDocument = () => {
    setPreview(!preview);
  };
  React.useEffect(() => {
    if (value) {
      setFiles([value]);
    }
  }, [value, setFiles]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  };
  return (
    <FileUploadContainer>
      <Button
        variant="contained"
        color={value ? "primary" : "error"}
        onClick={handleOpen}
      >
        {label}
      </Button>
      <DropzoneDialog
        dialogTitle={dialogTitle ?? "Upload file"}
        filesLimit={1}
        fileObjects={files}
        open={open}
        onSave={handleSave}
        acceptedFiles={["image/jpeg", "image/png", "application/pdf"]}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={handleClose}
      />
      {preview && (
        <CommonDialog
          maxWidth="lg"
          title={`Preview ${label}`}
          open={preview}
          handleSubmit={() => {}}
          handleToggleOpen={previewDocument}
        >
          <Document
            onLoadSuccess={onDocumentLoadSuccess}
            file={isValidUrl(value) ? value : URL.createObjectURL(value[0])}
          >
            <Page
              renderTextLayer={false}
              renderAnnotationLayer={false}
              pageNumber={1}
            />
          </Document>
        </CommonDialog>
      )}
      {files && value && (
        <IconButton
          sx={{ boxShadow: 5 }}
          edge="start"
          onClick={() => downloadDocuments(files, label)}
          color="inherit"
          aria-label="close"
        >
          <CloudDownloadIcon />
        </IconButton>
      )}
      {value && (
        <IconButton
          sx={{ boxShadow: 5 }}
          edge="start"
          onClick={() => previewDocument()}
          color="inherit"
          aria-label="close"
        >
          <VisibilityIcon />
        </IconButton>
      )}
      {value && !previewMode && (
        <IconButton
          sx={{ boxShadow: 5 }}
          edge="start"
          onClick={() => onDelete()}
          color="inherit"
          aria-label="close"
        >
          <DeleteIcon color="error" />
        </IconButton>
      )}
    </FileUploadContainer>
  );
};
