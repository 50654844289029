import { GridLocaleText } from '@mui/x-data-grid';
import { isObject, isString } from 'formik';
import { TFunction } from 'i18next';
import PDFMerger from 'pdf-merger-js';
import { toast } from 'react-hot-toast';
import { ReactImageGalleryItem } from 'react-image-gallery';
import styled from 'styled-components';
import { IAuction } from '../services/auction-service';

export const mobileVersionWidth = 900;
export const FinalDestinations: Record<string, any> = {
  'Sofia':'Sofia',
  'Plovdiv':'Plovdiv',
  'Vratsa':'Vratsa',
  'Burgas':'Burgas',
  'Pazar':'Novi pazar (Shumen)',
}
export enum VehicleStatus {
  NEW ='New',
  LOADED = 'Loaded container',
  IN_TRANSIT = 'In transit',
  DELIVERED = 'Arrived',
  UNLOADED = 'Unloaded',
  WAITING_DOCUMENTS = 'Pending documents',
  DOCUMENTS_COMPLETED = 'Documents completed',
  UNDER_CLEARENCE = 'Under clearence',
  UNDER_CUSTOMS = 'Under customs inspection',
  CLEARED = 'Customs clearance completed',
  PAID = 'Paid',
  PICKED_UP = 'Picked Up',
  
}
export const VehicleTypes: Record<string, any> = {
  'Car':'Car',
  'Jet ski / Boat':'Jet ski / Boat',
  'Motorbike':'Motorbike',
  'ATV/UTV': 'ATV/UTV',
  'PACKAGES':'PACKAGES',
  'MACHINES':'MACHINES',
  'Others':'Others',
}

export enum CLEARENCE {
  CLEARED = "COMPLETED",
  UNDER_CLEARENCE = "UNDER_CLEARENCE",
}

export const ClearenceTypes: Record<string, any> = {
  'COMPLETED': 'Customs cleared',
  'UNDER_CLEARENCE': 'Under clearance',
}
export const ContainerStatuses: Record<string, any> = {
  "LOADED": "Loaded",
  "IN_TRANSIT": "In transit",
  "DELIVERED" : "Arrived container",
  "UNLOADED" : "Unloaded",
  "REQUESTED" : "Unknown container",
}
export const TruckStatuses: Record<string, any> = {
    "ASSIGNED" : "ASSIGNED",
    "DELIVERED" : "ARRIVED",
}
export enum UserRoles {
  CLIENT = 'CLIENT',
  ADMIN = 'ADMIN',
  EMPLOYEE = 'EMPLOYEE',
}
export const GridActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`
export const isValidDate = (d: any) =>  {
  return d instanceof Date;
}
export const deleteDocument = (
  documents: File[],
  documentName: string,
  setFieldValue: (field: string, value: any) => void
) => {
  const filteredDocs = documents.filter(function (obj) {
    return obj.name !== documentName;
  });
  setFieldValue("documents", filteredDocs);
};
export const mapImagesToGalleryItems = (images: string[]) : ReactImageGalleryItem[] => {
  return images?.map((file) => {
    return {
      original: file,
      thumbnail: file,
      sizes: "278",
      loading: "eager"
    };
  });
}
export const decodeVin = (vin: string) => {
  window.open(`https://www.vindecoder.pl/${vin}`, "_blank");
};
export const trackContainer = (trackingUrl?: string, containerNumber?: string) => {
  if(!trackingUrl) {
    return;
  }
  window.open(`${trackingUrl}${containerNumber}`, "_blank");
};
export const viewStock = (stock: string, auction?: IAuction) => {
  if(!auction || !auction?.trackingUrl)
  return;
  let {trackingUrl} = auction;
  if(!/(http(s?)):\/\//i.test(trackingUrl)) {
    trackingUrl = 'https://' + trackingUrl;
  }
  window.open(`${trackingUrl}${stock}`, "_blank");
};
export const copyText = (stringToCopy: string, successMesssage: string) => {
    navigator.clipboard.writeText(stringToCopy);
    toast.success(successMesssage);
};
export const downloadDocuments = async (documents: any, name: string) => {
  const merger = new PDFMerger();
  if (documents.length === 0) return;

  for (const document of documents) {
    await merger.add(document);
  }
  await merger.save(name);
};
export const isValidUrl = (urlString: string)=> {
      try { 
      	return Boolean(new URL(urlString)); 
      }
      catch(e){ 
      	return false; 
      }
}
  export const ConsoleLog = ({ children }: any) => {
    return "";
};
    export const getFileName = (file: any) => {
    if (isString(file)) {
      return file.split("%2F").pop()?.replace("%", " ");
    }
    if (isObject(file)) {
      return file[0].name;
    }
  };
  export function GridTolbarLocale(t: TFunction): Partial<GridLocaleText> {
    return ({
      toolbarDensity: t('Size'),
      toolbarDensityLabel: t('Size'),
      columnMenuFilter: t('Filter'),
      toolbarDensityCompact: t('Small'),
      toolbarDensityStandard: t('Medium'),
      toolbarDensityComfortable: t('Large'),
      toolbarColumns: t("Columns"),
      toolbarExport: t("Export"),
      toolbarFiltersTooltipHide: t("Hide All"),
      columnMenuShowColumns: t("Show columns"),
      columnMenuSortAsc: t("Sort by ASC"),
      columnMenuSortDesc: t("Sort by DESC"),
      columnMenuHideColumn: t("Hide"),
      toolbarQuickFilterPlaceholder: t("Search"),
      noRowsLabel: t("No rows")
    })
}
