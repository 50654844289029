import { Typography } from "@mui/material";
import React from "react";
import { CommonDialog } from "../../components/CommonDialog";
import { FileUpload } from "../../components/FileUpload";
import { IVehicle } from "../../services/vehicle-service";
import { getFileName } from "../../utils";
interface IVehicleDetailsProps {
  open: boolean;
  data?: IVehicle;
  handleToggleOpen: () => void;
}
export const VehicleDocumentsPreview: React.FC<IVehicleDetailsProps> = ({
  handleToggleOpen,
  open,
  data,
}) => {
  const dialogClose = () => {
    handleToggleOpen();
  };

  const otherDocuments = data && data.otherDocuments ?  [data.otherDocuments] : [];
  return (
    <CommonDialog
      title="Documents"
      open={open}
      //handleSubmit={handleFormSubmit}
      handleToggleOpen={dialogClose}
    >
      {data?.title && (
        <>
          <Typography>Title</Typography>
          <FileUpload
            onFileSelect={() => {}}
            onDelete={() => {}}
            previewMode
            value={data?.title}
            label={getFileName(data?.title)}
          />
        </>
      )}
      {data?.invoice && (
        <>
          <Typography>Bill of sale</Typography>
          <FileUpload
            onFileSelect={() => {}}
            onDelete={() => {}}
            value={data?.invoice}
            label={getFileName(data?.invoice) ?? "Bill of sale"}
          />
        </>
      )}
      {data?.motusInvoice && (
        <>
          <Typography>Motus invoice</Typography>
          <FileUpload
            onFileSelect={() => {}}
            onDelete={() => {}}
            previewMode
            value={data?.motusInvoice}
            label={getFileName(data?.motusInvoice)}
          />
        </>
      )}
      {data?.technicalReport && (
        <>
          <Typography>Techical report</Typography>
          <FileUpload
            onFileSelect={() => {}}
            onDelete={() => {}}
            previewMode
            value={data?.technicalReport}
            label={getFileName(data?.technicalReport)}
          />
        </>
      )}
      {data?.taxAssessment && (
        <>
          <Typography>Tax assesment</Typography>
          <FileUpload
            onFileSelect={() => {}}
            onDelete={() => {}}
            previewMode
            value={data?.taxAssessment}
            label={getFileName(data?.taxAssessment)}
          />
        </>
      )}
      {data?.powerOfAttorney && (
        <>
          <Typography>Power of Attorney</Typography>
          <FileUpload
            previewMode
            onFileSelect={() => {}}
            onDelete={() => {}}
            value={data?.powerOfAttorney}
            label={getFileName(data?.powerOfAttorney) ?? "Power of Attorney"}
          />
        </>
      )}
      {data && data.otherDocuments && otherDocuments.map((status: any, index: number) => {
        const name = Object.keys( otherDocuments[index] );
        console.log(name, "INDEX", status)
        return (
          <>
            <Typography>{name[0]}</Typography>
            <FileUpload
              previewMode
              onFileSelect={() => {}}
              onDelete={() => {}}
              // @ts-ignore
              value={data.otherDocuments[name[0]].link}
              label={
                // @ts-ignore
                data.otherDocuments[name[0]] &&
                // @ts-ignore
                data.otherDocuments[name[0]].link && getFileName(data.otherDocuments[name[0]].link)
              }
            />
          </>
        )

      })}
    </CommonDialog>
  );
};
